import React, { useContext } from "react";
import { View, Image, Text, StyleSheet, TouchableOpacity, TextInput, ActivityIndicator, Linking} from 'react-native';
import {styles} from '../foundation/styles';
import homeStyles from './styles';
import UserContext from '../user';
import axios, {setAuthorizationHeader} from "../commons/axios";
import {LoginAction, LogoutAction} from "../navigator/actions";
// import {AsyncStorage} from "react-native-web";
import {UnassignedUserAction, WizardCloudChooserAction, WizardSchoolChooserAction, WizardCourseChooserAction} from './views';
import {getCookie, isWeb} from "../commons/utils";
import ChangeLog from "./change_log";
import {Observer} from "mobx-react";
import $ from 'jquery';


export let loginUsername = '';
export let loginPwd = '';


const Link = ({ href, style, children }) => (
  <Text
    accessibilityRole="link"
    target="_blank"
    href={href}
    onPress={() => {
      Linking.openURL(href);
    }}
    style={StyleSheet.compose(
      styles.link,
      style
    )}
  >
    <Text>{children}</Text>
  </Text>
);


export const DEBUG = false;
const STORAGE_CREDENTIALS_KEY = 'EgafDocenti:credentials';


export default class Login extends React.Component{

  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      initialLoading: true,
      username: '',
      password: '',

      text: '',

      errorMessage: '',
      isLoading: false
    };
  }

  static navigationOptions = {
    headerLeft: null,
  };

  async initApp() {
    // Call Endpoint returning all (clouds, locations, courses and classes) to set defaults
    const completeCloudsResp = await axios.get("users/", {
      params: {
        allUserView: true,
        COD_LOGIN: this.context.COD_LOGIN
      }
    });

    console.info('Clouds complete', completeCloudsResp.data);
    const clouds = completeCloudsResp.data;
    this.context.currentCloud = clouds[0];
    this.context.hasMultiClouds = clouds.length > 1;

    // Then get all locations
    const locations = this.context.currentCloud.locations;
    console.info('Locations', locations);
    this.context.currentLocation = locations[1]; // TODO choose the first one
    this.context.hasMultiLocations = locations.length > 1;

    // Then get all courses
    const courses = this.context.currentLocation.corsi;
    console.info('Courses', courses);
    this.context.currentCourse = courses[0];
    this.context.hasMultiCourses = courses.length > 1;

    // Then get all classes for the current course
    const classes = this.context.currentCourse.classi;
    console.info('Classes', classes);
    this.context.classes = classes;
    this.context.hasMultiClassesForCourse = classes.length > 1;

  }

  async checkConnection() {

    try {
      const completeCloudsResp = await axios.get("users/", {
        params: {
          allUserView: true,
          COD_LOGIN: this.context.COD_LOGIN
        }
      });
      console.info('Clouds complete', completeCloudsResp.data);
    } catch (e) {
      console.info('Request error ', e);
    }
  }

  // Prevent pressing TAB
  _onKeyDown(e) {
    try {
      const key = e.key || e.keyCode;
      if (key === 'Tab' && !this.props.navigation.isFocused()) {
        e.preventDefault();
      }
    } catch (e) {
      console.log('error for event key', e);
    }
  }

  async devLogin(e) {

      this.setState({
        errorMessage: '',
        isLoading: true
      });

      try {
        const loginResponse = await axios.post("login/", {
          // username: this.state.username,
          // password: this.state.password
          username: 'M.FANTINELLI@HOOPCOMMUNICATION.IT',
          password: 'pleiadi'
        });

        // if authentication process is ok
        if (loginResponse.data.success) {
          // Login ok, now retrieve all info to init app
          this.context.authenticated = true;
          this.context.COD_LOGIN = ''; // Now hardcoded
          this.context.token = loginResponse.data.authToken; // Now hardcoded
          this.context.refreshToken = loginResponse.data.refreshToken; // Now hardcoded

          // Set axios header
          setAuthorizationHeader(this.context, this.props.navigation);

          // await this.initApp();
          const defaults = await this.context.initWithCloud();
          console.info('defaults CLOD/LOCATION/COURSE:', defaults);

          // First check if user is unassigned
          if (defaults.userUnassigned) {
            this.props.navigation.navigate(UnassignedUserAction);
            this.resetLoginForm();
            return;
          }

          // Evaluating defaults, first check if there is default cloud
          if (!defaults.defaultCloud) {
            // If there isn't a deafultCloud call wizard
            this.props.navigation.navigate(WizardCloudChooserAction);
            this.resetLoginForm();
            return;
          } else if (!defaults.defaultLocation) { // Then check if there is default location
            // If there isn't a deafultLocation call wizard
            this.props.navigation.navigate(WizardSchoolChooserAction);
            this.resetLoginForm();
            return;
          } else if (!defaults.defaultCourse) { // Then check if there is default course
            // If there isn't a deafultLocation call wizard
            this.props.navigation.navigate(WizardCourseChooserAction);
            this.resetLoginForm();
            return;
          }

          this.resetLoginForm();
          this.props.navigation.dispatch(LoginAction);
        } else {
          this.setState({
            errorMessage: 'Inserisci credenziali valide per eseguire il login',
            isLoading: false
          })
        }

      } catch (e) {
        console.log('Error during login ');
        console.info('Error: ', e.response);

        if (e.response ) {
          this.setState({
            errorMessage: 'Inserisci credenziali valide per eseguire il login',
            isLoading: false
          })
        } else {
          this.setState({
            errorMessage: 'Si è verificato un errore durante il login, riprovare più tardi grazie.',
            isLoading: false
          })
        }
      }

      return true;

  }

  componentDidMount() {
    // Simulate loggin in  for testing purpose
    if (isWeb()) {
      document.addEventListener("keydown", (e) => this._onKeyDown(e));
    }

    // sessionStorage Check
    if (isWeb() && sessionStorage) {
      const savedCredentials = sessionStorage.getItem(STORAGE_CREDENTIALS_KEY);
      const credentials = JSON.parse(savedCredentials);
      if (credentials && credentials.username && credentials.password) {
        this.login(credentials.username, credentials.password);
      }
    }

    this.setState({
      initialLoading: false,
    })

    // Uncomment to ensble changelog logic
    // this.toggleChangeLog();
  }

  componentWillUnmount() {
    console.log('LOGIN UNMOUNTED');
    if (isWeb()) {
      document.removeEventListener("keydown", (e) => {});
    }
  }

  resetLoginForm() {
    this.setState({
      username: '',
      password: '',
      text: '',
      isLoading: false,
    })
  }

  async loginByForm(e) {
    console.info('login event', e);
    e.preventDefault();
    await this.login();
  }

  async login(username=null, password=null) {

    window.history.pushState({}, "Egaf Portale Docenti", null);

    const passedUsername = username || this.state.username;
    const passedPassword = password || this.state.password;

    if (passedUsername && passedPassword){

      this.setState({
        errorMessage: '',
        isLoading: true
      });

      try {
        const queryParams = new URLSearchParams(window.location.search);
        const alias = queryParams.get("alias");
        const loginResponse = await axios.post("login/?alias=" + alias, {
          username: passedUsername.trim(),
          password: passedPassword
          // username: 'M.FANTINELLI@HOOPCOMMUNICATION.IT',
          // password: '02290baeb9c3a93475d255cfbc860c6e'
        });

        const loginResp = loginResponse.data;
        // console.info('LOGINRESP:', loginResp);

        // if authentication process is ok
        if (loginResp.success) {
          // Login succeded, save in memory username e password for iframe...
          loginUsername = passedUsername;
          loginPwd = passedPassword;

          // Login ok, now retrieve all info to init app
          this.context.authenticated = true;
          this.context.COD_LOGIN = ''; // Now hardcoded
          this.context.token = loginResponse.data.authToken; // Now hardcoded
          this.context.refreshToken = loginResponse.data.refreshToken; // Now hardcoded

          // If present, save credentials in sessionStorage to reuse them later
          if (isWeb() && sessionStorage) {
            const credentials = {
              username: passedUsername,
              password: passedPassword
            };
            sessionStorage.setItem(STORAGE_CREDENTIALS_KEY, JSON.stringify(credentials));
          }
          // Storing credentials in browser password manager (if exists)
          console.info('SAVING CREDENTIALS');
          console.info('navigator', navigator);
          console.info('window.PasswordCredential', window.PasswordCredential);
          console.info('------------------');
          if (isWeb() && navigator && window.PasswordCredential) {
              // Storing password (specially for Chrome)
            const c = new window.PasswordCredential({
                id: passedUsername,
                password: passedPassword
            });
            navigator.credentials.store(c);
          }

          // Set axios header
          setAuthorizationHeader(this.context, this.props.navigation);

          // await this.initApp();
          const defaults = await this.context.initWithCloud();
          console.info('defaults CLOD/LOCATION/COURSE:', defaults);

          // First check if user is unassigned
          if (defaults.userUnassigned) {
            this.props.navigation.navigate(UnassignedUserAction);
            this.resetLoginForm();
            return;
          }

          // Evaluating defaults, first check if there is default cloud
          if (!defaults.defaultCloud) {
            // If there isn't a deafultCloud call wizard
            this.props.navigation.navigate(WizardCloudChooserAction);
            this.resetLoginForm();
            return;
          } else if (!defaults.defaultLocation) { // Then check if there is default location
            // If there isn't a deafultLocation call wizard
            this.props.navigation.navigate(WizardSchoolChooserAction);
            this.resetLoginForm();
            return;
          } else if (!defaults.defaultCourse) { // Then check if there is default course
            // If there isn't a deafultLocation call wizard
            this.props.navigation.navigate(WizardCourseChooserAction);
            this.resetLoginForm();
            return;
          }

          this.resetLoginForm();
          this.props.navigation.dispatch(LoginAction);
        } else {
          this.setState({
            errorMessage: 'Inserisci credenziali valide per eseguire il login',
            isLoading: false
          })
        }

      } catch (e) {
        console.log('Error during login ');
        console.info('Error: ', e.response);

        if (e.response ) {
          this.setState({
            errorMessage: 'Inserisci credenziali valide per eseguire il login',
            isLoading: false
          })
        } else {
          this.setState({
            errorMessage: 'Si è verificato un errore durante il login, riprovare più tardi grazie.',
            isLoading: false
          })
        }
      }

      return true;

    } else {
      this.setState({
        errorMessage: 'Inserisci username e password',
        isLoading: false
      })

      return false;
    }

  }

  onSubmit(e) {
    console.info('CALLED ONSUBMIT');

    const key = e.key || e.keyCode;
    if (key === "Enter"){
      this.loginByForm(e);
    }
    return false;
  }

  toggleChangeLog() {
    const changeLogCurrentStatus = this.context.showChangeLog;
    if (changeLogCurrentStatus) {
      // CL is open, close it
      this.context.showChangeLog = false;
    } else {
      // CL is closed, check cookies before opening it
      const changeLogCookie = getCookie("hidechangelog");
      if (!changeLogCookie) {
        this.context.showChangeLog = true;
      }
    }

  }

  render() {

    if (this.state.initialLoading) {
      return (
        <View style={homeStyles.container}>
          <ActivityIndicator size={'large'} />
        </View>
      )
    } else {
      return (
        <View style={[homeStyles.container, {position: 'relative'}]}>

          <Observer>
          {
            () => {
              if (this.context.showChangeLog) {
                return <ChangeLog />
              } else {
                return null;
              }
            }
          }
          </Observer>

          <View style={homeStyles.loginWrapper}>
            <View style={homeStyles.loginLoader}>
              {this.state.isLoading?
                <ActivityIndicator size={'small'}/>
                :
                null
              }

            </View>
            <Text style={homeStyles.title}>Log in area</Text>
              <form id={'login-form'} autoComplete={'on'} onSubmit={(e) => {this.loginByForm(e)}} method={'POST'} action={'.'} style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'center'
              }}>

              <TextInput
                name={'username'}
                autoComplete={'email username'}
                textContentType={'email'}
                id={'email'}
                autoCorrect={false}
                style={styles.loginTextInput}
                placeholder={'email'}
                placeholderTextColor={'#b1b1b1'}
                onChangeText={(username) => this.setState({username})}
                value={this.state.username}
              />
              <TextInput
                name={'password'}
                autoCapitalize={'none'}
                autoComplete={'current-password'}
                id={'current-password'}
                autoCorrect={false}
                // textContentType={'password'}
                style={styles.loginTextInput}
                placeholder={'password'}
                placeholderTextColor={'#b1b1b1'}
                onChangeText={(password) => this.setState({password})}
                onKeyPress={(e)=>this.onSubmit(e)}
                value={this.state.password}
                secureTextEntry={true}
              />

              <TouchableOpacity
                onPress={(e)=>this.loginByForm(e)}
              >
                <Text style={homeStyles.loginBtn}>Login</Text>
              </TouchableOpacity>

              <View style={{marginTop: 20}}>
                <Link href="https://lcps.egaf.it/index.php?wp=resetpwdr">
                  Password dimenticata?
                </Link>
              </View>

              </form>
          </View>
          {this.state.errorMessage?
            <Text style={styles.loginErrorMessage}>{this.state.errorMessage}</Text>
            :
            null
          }
        </View>
      );
    }


  }

};


export const LogoutButton = (props) => {

  const store = useContext(UserContext);

  const logout = async () => {
    const logoutResponse = await axios.post("logout/", {
      refreshToken: store.refreshToken
    });
    console.info('LOGOUT', logoutResponse);
    if (logoutResponse.data.success) {
      store.resetStore();
      props.navigation.replace(LogoutAction);
    }
  };

  return(
      <TouchableOpacity
        onPress={() => logout()}
        style={styles.logOutW}
      >
        <Image style={styles.logOutIcon} source={require('../commons/images/logout.svg')}/>
        {/*<Text style={[styles.buttonHeader, styles.buttonsHeaderAlignmentRight]}>Logout</Text>*/}
      </TouchableOpacity>
  );
}
